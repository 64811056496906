<template>
  <!-- index START -->
  <div class="index" v-if="box">
    <div
      :class="[
        'inner',
        { 'hide' : loginError || loginSuccess }
      ]"
    >
      <div class="animated-on-change">
        <!-- TEST ----- START -->
        <input
          type="text"
          class="test-input-terminal"
          :placeholder="'QR Code'"
          v-model="qrcodeTest"
          v-on:keyup.enter="onEnter"
          v-if="showQrcodeTest"
        />
        <!-- TEST ----- END -->

        <!-- index:main:headline START -->
        <h1
          class="headline"
          v-html="$t('outdoor.index.headline')"
        ></h1>
        <!-- index:main:headline END -->

        <!-- index:main:box:status START -->
        <box-status
          :boxOpened="boxOpened"
          :fakeHours="fakeHours"
          :box="box"
        />
        <!-- index:main:box:status END -->

        <!-- index:main:box:error START -->
        <box-error
          v-if="boxOpenedCatchError.isError"
          :boxOpenedCatchError="boxOpenedCatchError"
        />
        <!-- index:main:box:error END -->

        <!-- index:qr-codes START -->
        <div
          class="qr-codes"
          v-if="boxOpened.isOpen && (box.hasAppCheckin === 1 || box.hasSmsCheckin === 1)"
        >
          <div class="qr-code download">
            <div class="inner">
              <img :src="`${rootUrl}/qr/?d=http://anybox-www.int5.syreta.com/?boxID=${box.id}&locale=${boxLang}&fg=353635`"/>
            </div>
          </div>
        </div>
        <!-- index:qr-codes END -->
      </div>

      <!-- index:scan START -->
      <div
        :class="[
          'scan',
          { 'hide' : !boxOpened.isOpen
          || boxOpenedCatchError.isError
          || loginError
          || loginSuccess }
        ]"
      >
        <box-bottom-info
          :boxOpened="boxOpened"
          :box="box"
        />
      </div>
      <!-- index:scan END -->

    </div>

    <!-- index:box:error:border START
    <div
      :class="[
        'error-border error-border-top',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    <div
      :class="[
        'error-border error-border-right',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    <div
      :class="[
        'error-border error-border-bottom',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    <div
      :class="[
        'error-border error-border-left',
        { 'show' : !boxOpened.isOpen || boxOpenedCatchError.isError }
      ]"
    ></div>
    index:box:error:border END -->
  </div>
  <!-- index END -->
</template>

<script>
export default {
  name: 'index',
  components: {
    BoxStatus: () => import('../components/index/box/Status.vue'),
    BoxError: () => import('../components/index/box/Error.vue'),
    BoxBottomInfo: () => import('../components/index/box/Bottom.vue'),
  },
  data() {
    return {
      fakeHours: null,

      /**
       * Settings
       */
      settings: null,
      intervalValue: 10000,

      /**
       * box (info)
       */
      boxOpened: {
        isOpen: true,
        status: '',
      },
      boxOpenedCatchError: {
        isError: false,
        status: '',
      },
      loginError: false,
      loginSuccess: false,

      /**
       * box (token)
       */
      user: null,
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',
      /**
       * QR Code
       */
      qrcode: '',
      qrcodeTest: '',
      showQrcodeTest: false,
      /**
       * Root URL
       */
      rootUrl: localStorage.getItem('anybox_outdoor_api_url'),
    };
  },
  async created() {
    // get & set settings
    this.settings = await this.FETCH_SETTINGS();
    if (this.settings) {
      this.intervalValue = this.settings.app.timeouts.boxIsOpen;
    }

    // set token
    if (this.$route.query.token && this.$route.query.locale) {
      localStorage.setItem('boxToken', this.$route.query.token);
      localStorage.setItem('boxLang', this.$route.query.locale);
      this.boxToken = localStorage.getItem('boxToken');
      this.boxLang = localStorage.getItem('boxLang');
    }

    // if boxToxen then fetch box
    if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
      this.box = await this.FETCH_BOX();

      // check status of box
      if (this.box) {
        this.isBoxOpen();

        // GET FAKE HOURS
        this.fakeHours = await this.FETCH_FAKE_HOURS();
      }
    }

    window.addEventListener('keydown', this.keyDownHandler);
  },
  methods: {
    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    async FETCH_BOX() {
      await this.$store.dispatch('setBoxOutdoor', {
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.boxOutdoor;
    },

    async FETCH_FAKE_HOURS() {
      await this.$store.dispatch('setFakeHours', {
        payload: {
          boxID: this.box.id,
        },
      });
      return this.$store.getters.fakeHours;
    },

    onEnter() {
      // 6F0COFHJTMQG2DO4LXNUGAAFM6MI0SEEJ8FQMBBE5L3G6CT0GKBDHSTJF0FF2JHKEXO4
      // P43LCL56XS32DJBRU4F2P35C769QCJRSBLMXPID3XIBVVCEIIBPPLJ1H8EU7
      if (this.box && this.qrcodeTest && this.boxLang) {
        // login throught terminal
        this.loginDoor(this.box.id, this.qrcodeTest, this.boxLang);
      }
    },

    keyDownHandler(e) {
      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';

      if (this.qrcodeTest === '') {
        this.qrcode += char;

        // login throught terminal
        if (e.keyCode === 13 && this.box && this.qrcode && this.boxLang) {
          this.loginDoor(this.box.id, this.qrcode, this.boxLang);
        }
      }
    },

    loginDoor(boxID, qrCode, boxLang) {
      const formData = new FormData();
      formData.append('boxID', boxID);
      formData.append('qrCode', qrCode);
      formData.append('locale', boxLang);
      this.$http.post(`${localStorage.getItem('anybox_outdoor_api_url')}/loginDoor`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_outdoor_api_key')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            this.loginSuccess = true;
            this.success(`${this.$t('outdoor.index.scan.success')}`);

            const { user } = response.data;
            const auth = response.data.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH_OUTDOOR', auth);
            localStorage.setItem('authOutdoor', auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.user = user;
            this.$store.commit('SET_USER_OUTDOOR', user);
            localStorage.setItem('userOutdoor', JSON.stringify(user));

            // clear input
            setTimeout(() => {
              this.qrcode = '';
              this.qrcodeTest = '';
              this.logoutUser(boxLang);
            }, 150);

            // reset login success variable
            setTimeout(() => {
              this.loginSuccess = false;
            }, 6000);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector('.el-notification')) {
                const elem = document.querySelector('.el-notification');
                elem.remove();
              }
            }, 6500);
          }
        })
        .catch((error) => {
          this.loginError = true;
          this.errorStatus = error.response.data;
          this.serverErrorMessages = this.errorStatus.errors;

          this.qrcode = '';
          this.qrcodeTest = '';
          console.clear();

          this.serverErrorMessages.forEach((message) => {
            this.error(`${this.$t(`error.${message.code}`)}`);
          });

          // reset login error variable
          setTimeout(() => {
            this.loginError = false;
          }, 6000);

          // delete created notification dom element
          setTimeout(() => {
            if (document.querySelector('.el-notification')) {
              const elem = document.querySelector('.el-notification');
              elem.remove();
            }
          }, 6500);
        });
    },

    logoutUser(boxLang) {
      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', boxLang);
      // Logout data
      this.$http.post(`${localStorage.getItem('anybox_outdoor_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_outdoor_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('authOutdoor')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH_OUTDOOR', null);
            localStorage.removeItem('authOutdoor');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER_OUTDOOR', null);
            localStorage.setItem('userOutdoor', null);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            console.log(this.errorStatus);
          }
        });
    },

    isBoxOpen() {
      this.isOpen();
      // check once in minute if opened or closed
      setInterval(() => {
        this.isOpen();
      }, this.intervalValue);
    },

    isOpen() {
      // check on init if opened or closed
      this.$http.get(`${localStorage.getItem('anybox_outdoor_api_url')}/box/isOpen`,
        {
          params: {
            boxID: this.box.id,
          },
          headers: {
            'X-Api-Key': `${localStorage.getItem('anybox_outdoor_api_key')}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const { isOpen, status } = response.data;

            // update notification
            this.boxOpened = {
              isOpen,
              status,
            };
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            // Close loading
            this.errorStatus = error.response.data;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.boxOpenedCatchError = {
                isError: true,
                status: `${this.$t(`error.${message.code}`)}`,
              };
            });
          }
        });
    },

    success(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 6000,
      });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
        showClose: false,
        duration: 6000,
      });
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
    clearInterval();
  },
  mounted() {
    if (Boolean(this.$route.query.showQrcodeTest) === true) {
      this.showQrcodeTest = true;
    } else {
      this.showQrcodeTest = false;
    }
  },
};
</script>
